import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import Info from "../Info";

import ImgPdf from "../../stylesheets/images/ImgPdf.png";
import ImgBms from "../../stylesheets/images/bms-components.jpg";


class Automation extends Component {
    render() {
        const { t } = this.props;

        console.log("Props: ",this.props);

        console.log(`${require('../../pdf/automation/Datasheet_LX-BMS-U24-MD.pdf')}`);
//Datasheet_LX-BMS-M32-BAC.pdf
        return (
            <div className="automation">

                <Info pageName="automation" pdfs={[]}/>

                <div className="longBody">
                    <div className="withPhoto">
                        <p>{t('automation.body.t_1')}</p>
                        <img className="ImgBms" src={ImgBms} alt="" />
                    </div>

                    <div className="pdf">
                        <h2>{t('automation.h_1')}</h2>


                        <table>
                            <caption>BAC SERIES CONTROLLERS</caption>
                            <tbody>
                            <tr><th>LX-BMS-M32-BAC</th><td><span>Lonix MultiController{"\n"} M32 Flexible, freely programmable I/O points</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-M32-BAC.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>




                        <table>
                            <caption>{t('automation.h_1_1')}</caption>
                            <tbody>
                            <tr><th>LX-BMS-M10</th><td><span>Lonix MultiController M10{"\n"}Flexible, freely configurable I/O points</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-M10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-BMS-U10</th><td><span>Lonix UnitController U10{"\n"}U10-FCU: Fan Coil Unit controls{"\n"}U10-AC: AC Unit (Split Unit) controls{"\n"}U10-IO: Input-output controls</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-U10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>MD SERIES CONTROLLERS</caption>
                            <tbody>
                            <tr><th>LX-BMS-U24-MD</th><td><span>UnitController U24</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-U24-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-BMS-U16-MD</th><td><span>UnitController U16</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-U16-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>                           
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_1_2')}</caption>
                            <tbody>
                            <tr><th>LX-BMS-TSP3.5-MD</th><td><span>	Lonix BMS Touch Screen Panel TSP3.5</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-TSP3.5-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-BMS-TSP10</th><td><span>Lonix BMS Touch Screen Panel TSP10</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TSP10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_1_3')}</caption>
                            <tbody>
                            <tr><th>LX-BMS-NIU</th><td><span>Lonix NIU Network Interface Unit</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-NIU.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-BMS-BIU</th><td><span>Lonix NIU Network Interface Unit</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-BMS-BIU.PDF')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_1_4')}</caption>
                            <tbody>
                            <tr><th>LX-LNI</th><td><span>Lonix Network Interface LNI</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-LNI.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-RCM</th><td><span>Remote Control Module RCM</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-RCM.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TSM</th><td><span>Time Scheduler Module TSM</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TSM.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>

                    </div>

                    <div className="pdf">
                        <h2>{t('automation.h_2')}</h2>
                        <table>
                            <caption>{t('automation.h_2_1')}</caption>
                            <tbody>
                            <tr className="hiddenRow"><th>LX-TH3-24</th><td><span> Modulating Modbus Thermostat (24VAC):{"\n"}4-pipe heating/cooling, auto-switching{"\n"}2-pipe heating/cooling, manual switching{"\n"}Cooling only{"\n"}Cooling only, VAV</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TH3-24.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr className="hiddenRow"><th>LX-TH3-230</th><td><span> Modbus Thermostats (230VAC):{"\n"}4-pipe heating/cooling, auto-switching{"\n"}2-pipe heating/cooling, manual switching{"\n"}Cooling only</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TH3-230.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TH3-FCU-LCD-CHM-MD</th><td><span>Modulating and Floating Modbus FCU Thermostat (24VAC):{"\n"}4-pipe heating and cooling, auto-switching{"\n"}2-pipe heating or cooling, manual switching</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TH3-FCU-LCD-CHM-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TH3-VAV-LCD-CHM-MD</th><td><span>Modulating Modbus VAV Thermostat (24VAC):{"\n"}Heating and cooling</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TH3-VAV-LCD-CHM-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TH6-FCU-LCD-R</th><td><span>Round FCU Thermostat (230 VAC){"\n"}On/Off, Modulating, Standalone/Modbus, 2-pipe/4-pipe</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheets_LX-TH6-FCU-LCD-R.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TH7-FCU-LCD-L</th><td><span>Square FCU Thermostat (230 VAC){"\n"}On/Off, Modulating, Standalone/Modbus, 2-pipe/4-pipe</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheets_LX-TH7-FCU-LCD-L.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TH8-FCU-LCD-L</th><td><span>Square FCU Thermostat (230 VAC){"\n"}On/Off, Modulating, Standalone/Modbus, 2-pipe/4-pipe</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheets_LX-TH8-FCU-LCD-L.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pdf">
                        <h2>{t('automation.h_3')}</h2>
                        <table>
                            <caption>{t('automation.h_3_1')}</caption>
                            <tbody>
                            <tr><th>LX-S1-6BTN</th><td><span>Touch led panel</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-S1-6BTN.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr className="hiddenRow"><th>LX-S3-L</th><td><span>Night/Alarm switch</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AP-P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-LC-S8-xBTN</th><td><span>Touch glass switches</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-LC-S8-xBTN-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-S4-xBTN</th><td><span>Lighting control switch</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-S4-xBTN.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr className="hiddenRow"><th>LX-S3-CH</th><td><span>Hotel card holder</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AP-S5-P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-S7-CH</th><td><span>Hotel card holder</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CH-S7.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-S4-CH-P</th><td><span>Hotel card holder</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet LX-S4-CH-P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-S4-CH-M</th><td><span>Hotel card holder (Mifare)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-S4-CH-M-A-Wh(Bl).pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-S7-DBS-S7</th><td><span>Hotel door bell system</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-DBS-S7.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
							<tr><th>LX-DBS-S8-MD</th><td><span>Hotel door bell system</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-DBS-S8-xxx-MD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_3_2')}</caption>
                            <tbody>
                            <tr><th>LX-TE-R-AP</th><td><span>Temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AP.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-R-AP-P</th><td><span>Temperature sensor, potentiometer</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AP-P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-R-AP-P-LCD</th><td><span>Temperature sensor, potentiometer & display</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AP-S5-P-LCD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-R-AP-S5-P</th><td><span>Temperature sensor & fan control panel</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AP-S5-P.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-RHTE-R-AP</th><td><span> Humidity & temperature sensor </span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-RHTE-R-AP.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-RHTE-R-AP-LCD</th><td><span>Humidity & temperature sensor with display</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-RHTE-R-AP-LCD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-CDTE-R-AP</th><td><span>Carbondioxide & temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CDTE-R-AP.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-CDTE-R-AP-LCD</th><td><span>Carbondioxide & temperature sensor, display</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CDTE-R-AP-LCD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_3_3')}</caption>
                            <tbody>
                            <tr><th>LX-TE-R-AC</th><td><span>Temperature sensor (optional potentiometer/display)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R-AC.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-RHTE-R-AC</th><td><span>Humidity & temp. sensor (optional potentiometer/display)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-RHTE-R-AC.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-CDTE-R-AC</th><td><span>Carbondioxide & temperature sensor (optional display)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CDTE-R-AC.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pdf">
                        <h2>{t('automation.h_4')}</h2>
                        <table>
                            <caption>{t('automation.h_4_1')}</caption>
                            <tbody>
                            <tr><th>LX-TE-O</th><td><span>Outdoor temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-O.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-R</th><td><span>Room temperature sensor (micro)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-R.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-D200</th><td><span>Duct temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-D200.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-DA</th><td><span>Duct average temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-DA.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-W100</th><td><span>Immersion sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-W100.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-F</th><td><span>Flying lead temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-F.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-TE-HT-200</th><td><span>Flue Gas Temperature Transmitter</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-TE-HT-D200.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-FPT</th><td><span>Frost protection thermostat</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-FPT.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_2')}</caption>
                            <tbody>
                            <tr><th>LX-RHTE-O</th><td><span>Outdoor humidity & temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-RHTE-O.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-RHTE-D</th><td><span>Duct humidity & temperature sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-RHTE-D.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_3')}</caption>
                            <tbody>
                            <tr><th>LX-CDTE-D</th><td><span>Carbondioxide and temperature sensor for duct</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CDTE-D.PDF')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_4')}</caption>
                            <tbody>
                            <tr><th>LX-CM-R</th><td><span>Carbonmonoxide sensor</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CM-R.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-CM-D</th><td><span>Carbonmonoxide sensor, duct</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-CM-D.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_5')}</caption>
                            <tbody>
                            <tr><th>LX-PDS-A-ADJ</th><td><span>Differential pressure switch for air</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PDS-A-ADJ.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PD-A</th><td><span>Differential pressure sensor for air</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PD-A.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PDS-W</th><td><span>Differential pressure switch for water</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PDS-W.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PG-W-10</th><td><span>Pressure sensor (gauge) for water (below 10 bar)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PG-W-10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PG-W-30</th><td><span>Pressure sensor (gauge) for water (below 30 bar)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PG-W-30.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PD-W-3</th><td><span>Differential pressure sensor for water (below 3 bar)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PD-W-3.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr className="hiddenRow"><th>LX-PD-W-5</th><td><span>Differential pressure sensor for water (below 5 bar)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PD-W-5.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PD-W-10</th><td><span>Differential pressure sensor for water (below 10 bar)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PD-W-10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PD-W-30</th><td><span>Differential pressure sensor for water (below 30 bar)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PD-W-30.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PD-R-LCD</th><td><span>Room pressure sensor (for technical rooms)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PD-R-LCD.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_6')}</caption>
                            <tbody>
                            <tr><th>LX-LUX</th><td><span>Illumination & temperature transducer</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-LUX.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_7')}</caption>
                            <tbody>
                            <tr><th>LX-AF</th><td><span>Air-flow transmitter</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-AF.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_8')}</caption>
                            <tbody>
                            <tr><th>LX-LFS-W</th><td><span>Liquid flow switch</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-LFS-W.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_9')}</caption>
                            <tbody>
                            <tr><th>LX-LSW-x</th><td><span>Liquid level switch</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-LSW-x.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_10')}</caption>
                            <tbody>
                            <tr><th>LX-WLD</th><td><span>Water leakage detector + alarm band</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-WL-AB.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_11')}</caption>
                            <tbody>
                            <tr><th>LX-SH-R</th><td><span>Smoke & heat detector</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-SH-R.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-SDP-D</th><td><span>Smoke detector (photoelectric), for duct</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-SDP-D.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                        <table>
                            <caption>{t('automation.h_4_12')}</caption>
                            <tbody>
                            <tr className="hiddenRow"><th>LX-PIR-W90C2</th><td><span>Motion sensor, corner model (90 degrees)</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/UserManual_Lonix_M10.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PIR-C360-24</th><td><span>Occupancy sensor, ceiling mount</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PIR-C360-24.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            <tr><th>LX-PIR-W140-24</th><td><span>Occupancy sensor, wall mount</span></td><td className="pdfLinkDL"><img className="ImgPdf" src={ImgPdf} alt=""/><a href={`${require('../../pdf/automation/Datasheet_LX-PIR-W140-24.pdf')}`} target="_blank" rel="noopener noreferrer"><span>Download</span></a></td></tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        );
    }
}
export default withTranslation()(Automation);

