
export const resEng = {
    'general': {
        'cookie': 'This website uses cookies to enhance the user experience.',
        'cookie_but': 'I understand',
        'sheet': 'datasheet',
    },
    'header': {
        'home2': 'LONIX SOLUTIONS',
        'home': 'HOME',
        'company': 'COMPANY',
        'news': 'NEWS',
        'products': 'PRODUCTS',
        'solutions': 'SOLUTIONS',
        'automation': 'AUTOMATION',
        'metering': 'METERING',
        'support': 'SUPPORT',
        'contact': 'CONTACT',
        'security': 'SECURITY',
        'software': 'SOFTWARE',
        'notfound': 'PAGE NOT FOUND',

        'normalCaps': {
            'software': 'Software',
            'automation': 'Automation',
            'metering': 'Metering',
            'components': 'Security Products',
            'accesscontrolsystem': 'Access Control System',
            'videosurveillance': 'Video Surveillance',
        },

        'components': 'SECURITY PRODUCTS',
        'accesscontrolsystem': 'ACCESS CONTROL SYSTEM',
        'videosurveillance': 'VIDEO SURVEILLANCE',

        'resources': 'Documents',

        'offices': 'Offices and Commercial Buildings',
        'hotels': 'Hotels and Hospitality',
        'sports_centers': 'Sports Centers',
        'data_centers': 'Data Centers & Production Facilities',
        'remotes': 'Remote Control & Monitoring',
    },
    'software': {
        'info': {
            'h_1': 'Lonix Software',
            'h_1_1': 'Building Operating System Platform',
            't_1': 'Lonix Software Products are based on the standard based COBA BOS Framework definitions. Compliant systems include a variety of systems ' +
                    'and devices, related with the full-fledged building automation and security system.',
        },
        'body': {
            'h_1': 'COBA Server Software',
            't_1': 'COBA Server Software is the software platform for integration and interoperability of building systems. Beyond normal SCADA, it provides ' +
                    'transparency to the building management and security systems through one uniform interface. COBA expands through drivers to a wide range of ' +
                    'existing and future systems and protocols, including e.g. BACnet, LonWorks, Modbus, M-BUS and others. ',
            'h_2': 'COBA Client Software',
            't_2': 'COBA Client is the professional monitoring application for all COBA connected systems, ranging from building automation and room controls ' +
                    'to emergency lighting and demanding security systems. The same client can be used for accessing remotely multiple sites. COBA Client is the ' +
                    'perfect choice for demanding professional users of monitoring applications.',
            'h_3': 'COBA WebUI Application',
            't_3': 'COBA WebUI is the user-friendly extension to professional monitoring applications. All COBA connected building management and security ' +
                    'systems are easy to access using a standard web browser. COBA WebUI provides significant advantages in terms of flexibility and ease of use. ' +
                    'The interface scales automatically to devices of different sizes from mobile to tablets.',
        }
    },
    'home': {
        'info': {
            'h_1':  'Forefront IBMS for Intelligent Green Buildings',
            't_1':  ''
        },
        'body': {
            'h_1':  'Lonix Solutions have been delivered worldwide since 1996\n',
            't_1':  'The Lonix product family includes automation, consumption measurement and security systems that integrate automatically with the COBA Building Operating ' +
                    'System (BOS) IoT platform.\n\n' +
                    'COBA BOS is a reliable and open IoT platform for connecting all systems securely and cost-effectively to current and future IoT services. The COBA BOS ' +
                    'platform integrates thousands of manufacturers building automation, consumption measurement, home automation, hotel guest room management, access control ' +
                    '& intruder detection, fire alarm and emergency lighting systems at thousands of reference sites. The COBA platform provides significant added value to all ' +
                    'connected systems by bringing usability to a new level securely.\n\n' +
                    'Lonix solutions are suitable for all types of real estate. The solutions use open standard interfaces from the outset, allowing easy upgrades, low maintenance ' +
                    'costs and a long life cycle for all systems. Systems delivered more than 20 years ago are still widely used. The Lonix solution is a safe and economically sound ' +
                    'investment.',
            't_x':  'Lonix is a Trademark of Coba International..Forefront IBMS for Intelligent Green Buildings',
            't_1x':  'COBA International Ltd. provides versatile systems and advanced solutions for Intelligent Buildings using Lonix as its brand name.' +
                    'The cutting-edge Lonix Automation, integrating with Lonix Security, creates the perfect solution for indoor comfort, energy efficiency ' +
                    'and security, optimal for all buildings where excellent quality is a requirement. \n\n' +
                    'The scalable Lonix System allows for versatile solutions for a wide variety of needs, with references all around the world. \n\n' +
                    'Fostering the values of sustainable development, we help you to create simply better built environments - lasting for future generations to come.',
            'h_2':  'Lonix offers the best solutions\n',
            't_2':  'Lonix Solutions has comprehensive, versatile competence on innovative solutions in intelligent, integrated automation and security systems for a ' +
                    'very wide variety of buildings. We are leading specialists not only in advanced automation and controls but also in the design of integrated, ' +
                    'intelligent systems to enable energy-efficient buildings. We know the needs of different buildings and know how to benefit from the ' +
                    'possibilities of forefront technology.\n\nLonix is considered one of the industry leaders in technology, intelligent applications and modern design. ' +
                    'Headquartered in Helsinki, Lonix has been involved in successfully implementing integrated automation solutions different countries around the world. ' +
                    'Our partnership network today covers Northern Europe, East Europe, Russia, South America and Central America, Middle East, Eastern Africa and Far East.\n\n' +
                    'The Lonix Controllers are made in EU according to ISO 9001 and ISO 14001 standards. The Controllers comply with Fire Certification and ' +
                    'GOST R Certification as required by the Russian authorities.',
        },
    },
    'solutions': {
        'info': {
            'h_1':  '',
            't_1':  'The cutting-edge Lonix System offers you the perfect tool for sustainable development and Green Buildings. Scalability of the system ' +
                    'allows for efficient solutions for all kinds of buildings.'

        },
        'body': {
            'h_1':  'Offices and Commercial Buildings',
            't_1':  'The solution combines the most advanced, open technologies in the fields of automation and security into a complete solution for ' +
                    'intelligent buildings. The solution guarantees an efficient yet economical environment for your business.',
            'a_1':  'Case Study',
            'a_2':  'Read more',
            'h_2':  'Hotels and Hospitality',
            't_2':  'Scalable Lonix solution suits for both high-class and low-end hotels. The solution offers excellent guest comfort in combination with reduced energy consumption. ' +
                    'Seamless integration allows for automatic adjustment and easy monitoring of all systems. The ecological impact is significant - the perfect solution for a healthier planet.',
            'h_3_0':  'Sports Centers & Stadiums',
            't_3_0':  'Integrated Lonix Building Automation and Security Systems suit well for different type of Sport Facilities.',
            'h_3_1':  'Data Centers & Production Facilities',
            'h_4':  'Remote Monitoring & Control',
            't_4':  'The Integrated Remote monitoring & control solution is designed specifically for control needs of networked remote sites, such as ' +
                    'base stations of mobile networks or monitoring nodes of fibre networks. Thousands of remote sites can easily be connected to one central ' +
                    'user interface.'
        }
    },
    'news': {
        'info': {
            'h_1': 'The Latest From Lonix',
            't_1': 'The cutting-edge Lonix Automation, integrating with Lonix Security, creates the perfect'
        },
        'body': {
            't_1': 'Press release',
            't_2': 'Product news',
            't_3': 'Date',
            't_4': 'Signed ',
            't_link': 'Link',
        },
        'articles': {
            'n_1_h': 'The Lonix site has changed its outlook',
            'n_1_t': 'The site also scales well with newer devices.'
        }
    },
    'support': {
        'info': {
            'h_1': 'Specifications - Manuals - Trainings',
            'h_1_1': 'Specifications - Manuals\n- Trainings'
        },
        'body': {
            't_1': 'This is the master library of materials, examples and manuals. Lonix has also a selction of training programs available in our ' +
                'training centers in Helsinki/Dubai or at customer sites.\n\n Should you need any more advice, do not hesistate to contact us for personal support.',

            'h_1': 'SPECIFICATIONS',
            'h_1_1': 'TECHNICAL SPECIFICATIONS',
            'h_2': 'MANUALS',
            'h_2_1': 'AUTOMATION CONTROLLERS',
            'h_2_2': 'SECURITY CONTROLLERS',
            'h_2_3': 'SOFTWARE',
            'h_3': 'TRAINING',
            'h_3_1': 'SYSTEM OVERVIEW',
            'h_4': 'SERVICES',
            't_4': 'Do not hesitate to contact us for further information or for quotation: sales [at] lonix.com',
        }
    },
    'products': {
        'info': {
            'h_1':  '',
            't_1':  'Lonix provides the core components for intelligent building automation, smart security and integrated building management systems. ' +
                    'The product portfolio includes the key enablers not only for smart controls and integrated systems, but also for efficient maintenance ' +
                    'and services.'
        },
        'body': {
            'link': 'Direct Link',
            'automation': 'Forefront products for intelligent BMS is our core competence. FLEX Series Controllers with all-inclusive control and monitoring ' +
                        'features, TOUCH Series for modern yet stunningly easy touch user interfaces, the comprehensive Building Operating System Software for ' +
                        'interoperability – everything standing for excellent quality and versatile features. ',
            'security': 'Lonix Security provides the integrated solution for outstanding security. The IP driven security systems are based on proven hardware ' +
                        'and world-class software, integrating smoothly with automation and building management systems. ',
            'metering': 'Lonix Water Metering is a reliable, remote, room-specific water metering system based on a standard fairway protocol and commonly ' +
                        'used meter technology. You can choose from wireless or wired bus-based M-Bus meters or traditional reliable impulse meters, depending on your ' +
                        'destination.',
            'software': 'Lonix Software Products are based on the Lonix COBA Building Operating System platform. COBA includes interfaces to the most common protocols, ' +
                        'including BACnet, LonWorks, Modbus, M-Bus, OPC and others. The Lonix system supports BACnet/IP as the communication protocol between the DDC panels ' +
                        'and the Control Server Software.',
        },
    },
    'automation': {
        'info': {
            'h_1':  'Lonix building management systems provide a reliable total solution for all your needs.',
            'h_1_1': 'Lonix building management\nsystems provide a reliable total\nsolution for all your needs',
            't_1':  ''
        },
        'body': {
            't_1':  'Automation, metering and security systems integrate flexibly into one and the same ' +
                    'control room. We also provide users with secure remote access without requirement for fixed ' +
                    'ip address, which allows low operator costs.\n\nOur selection of controllers, sensors, panels, interfaces, and software, ' +
                    'as well as compliant actuators and other field devices will provide all needed elements for your solution.'
        },
        'h_1': 'AUTOMATION CONTROLLERS & PANELS',
        'h_1_1': 'FLEX SERIES CONTROLLERS',
        'h_1_2': 'TOUCH SERIES PANELS',
        'h_1_3': 'CONNEX SERIES INTERFACES',
        'h_1_4': 'LEGACY APPLICATIONS',
        'h_2': 'FCU THERMOSTATS',
        'h_2_1': 'MODULAR THERMOSTATS',
        'h_3': 'ROOM SENSORS',
        'h_3_1': 'SWITCHES AND ACCESSORIES',
        'h_3_2': 'MODERN DESIGN',
        'h_3_3': 'FUNCTIONAL DESIGN',
        'h_4': 'BMS SENSORS',
        'h_4_1': 'TEMPERATURE',
        'h_4_2': 'HUMIDITY',
        'h_4_3': 'CARBONDIOXIDE (CO2)',
        'h_4_4': 'CARBONMONOXIDE (CO)',
        'h_4_5': 'PRESSURE',
        'h_4_6': 'ILLUMINATION',
        'h_4_7': 'AIR FLOW',
        'h_4_8': 'LIQUID FLOW',
        'h_4_9': 'LIQUID LEVEL',
        'h_4_10': 'WATER LEAKAGE',
        'h_4_11': 'SMOKE',
        'h_4_12': 'OCCUPANCY'
    },
    'metering': {
        'info': {
            'h_1':  'Energy and Water Consumption Sub-metering - Wired or Wireless',
            'h_1_1': 'Energy and Water Consumption\nSub-metering - Wired or Wireless',
            't_1':  'Lonix Solutions Consumption Metering is a reliable unit-specific measurement system based on the standard bus ' +
                    'protocol and commonly used meter technology. Depending on your destination, you can choose between wireless or wired M-Bus meters. ' +
                    'The lifetime of wired Mbus water meters is up to 25 years and the warranty period for meters is up to five years.\n\n' +
                    'The system automatically sends consumption readings over the Internet to a remote reading service, whereby consumption data can be ' +
                    'read easily using a standard Internet browser. The data can be transferred flexibly to the most common billing programs.',
        },
        'body': {
            'h_1':  'Remotely readable Lonix Consumption Metering',
            't_1':  'The Lonix Energy and Water Metering System includes energy and water meters, a central unit and readiness for remote reading using an Internet browser. ' +
                    'Consumption data is collected in the central unit, from which they are automatically sent to the remote reading service. Remote reading ' +
                    'service is provided for use by regulators and residents. The reading can always be checked from the display of energy or water meter if necessary. Lonix ' +
                    'consumption metering is effortlessly integrated into our automation system.',
            'h_2': 'Please contact!',
            't_2': 'Phone. +358 9 349 9853\ninfo [at] lonix.com / vedenmittaus [at] lonix.com ',
            'h_3': 'METERING DOWNLOADS (only finnish)'
        },
        'h_3': 'BROCHURES:',
        't_3': '',
        'h_4': 'WORKOUT TEMPLATES:',
        't_4': '',
        'h_5': 'INSTALLATION INSTRUCTIONS:',
        't_5': '',
        'h_6': 'OTHER MATERIALS:',
        't_6': ''
    },
    'security': {
        'info': {
            'h_1':  'Lonix Security Management System is scalable solution for all types of buildings',
            't_1':  'Lonix Security is the integrated IP driven solution for your outstanding security. The solution ' +
                    'allows for smooth integration with automation and building management systems.'
        },
    },
    'components': {
        'info': {
            'h_1': 'Fully Featured Solution for Outstanding Security',
            'h_1_1': 'Fully Featured Solution for\nOutstanding Security',
            't_1': 'Our selection of security products help you to realise high quality security solutions fulfilling any requirements.',
        },
        'h_1': 'ACCESS CONTROL AND INTRUDER ALARM SYSTEM',
        'h_1_1': 'V-SERIES CONTROLLERS',
        'h_2': 'CAMERAS',
        'h_2_1': 'INDOOR DOME CAMERAS',
        'h_2_2': 'FISH EYE PANORAMA CAMERAS',
        'h_2_3': 'BOX CAMERAS',
        'h_2_4': 'VANDAL DOME CAMERAS',
        'h_2_5': 'BULLET CAMERAS',
        'h_2_6': 'PTZ CAMERAS',
        'h_3': 'ACCESSORIES',
        'h_3_1': 'CAMERA ACCESSORIES',
    },
    'accesscontrolsystem': {
        'info': {
            'h_1':  'Intelligence for Access Control & Intruder Alarms',
            'h_1_1':  'Intelligence for Access Control\n& Intruder Alarms',
            't_1':  'The Lonix V-Series Controllers provide complete and fully featured hardware/firmware infrastructure ' +
                    'for access control and intruder alarm systems. The access control and intruder alarm system is controlled ' +
                    'and monitored by smart Network Controllers and Interface Panels.'
        },
        'v1000_h':  'V1000 Network Controller',
        'v1000_t':  'The V1000 Network Controller contains the full intelligence for system functionality, with access control ' +
                    'processing for up to 32 interface panels. The Network Controller contains a 32-bit processor running the Linux Operating ' +
                    'System. The Network Controller makes all online door decisions, input monitoring and output control for connected ' +
                    'interface panels.',
        'v100_h': 'V100 Reader Interface Panel',
        'v100_t': 'The V100 Reader Interface Panel connects with two readers through the Wiegand Interface',
        'v200_h': 'V200 Input Interface Panel',
        'v200_t': 'The V200 Input Interface Panel connects with 16 supervised input circuits',
        'v300_h': 'V300 Output Interface Panel',
        'v300_t': 'The V300 Output Interface Panel connects with 12 relay controllable devices',
        'v2000_h':  'V2000 Network Controller & Reader Interface Panel',
        'v2000_t':  'The V2000 Network Controller & Reader Interface Panel is the combination of the TCP/IP interface for server ' +
                    'connectivity and access control processing for two readers',
        'v-ip2-r_h': 'V-IP2-R/RW Door Controller-Reader & Interface Module',
        'v-ip2-r_t': 'The V-IP2-R/RW is the single-door networked access controller and contactless iCLASS reader coming with ' +
                     'interface module',
        'v-ip2-w_h': 'V-IP2-W Single Door Controller',
        'v-ip2-w_t': 'The V-IP2-W is the single-door access control panel that enables cost-effective installation and high performance' +
                     ' access control functionaliy.',
    },
    'videosurveillance': {
        'info': {
            'h_1': 'Integrated Security with Compliant Cameras',
            'h_1_1': 'Integrated Security with\nCompliant Cameras',
            't_1': 'Lonix Security includes the comprehensive solution for video surveillance. The digital video surveillance system comes ' +
                'with compliant camera range and proven integrations with the Lonix BOS Server software.',
        },
        'vms_h': 'Video Management System',
        'vms_t': 'The VMS software, coming with innovative features, is smoothly integrated as the elementary part of Lonix Security.',
        'cam_h': 'Cameras',
        'cam_t': 'With full range of cameras you can easily implement of all types of video surveillance solutions with high quality video.',
        'trash': {
            'h_1':  'Intelligenssiä pääsynvalvontajärjestelmille & tunkeutujien hälytyksille',
            't_1':  'Lonix V-sarjan ohjaimet tarjoavat täydellisen ja täysin varustellun laitteiston / laiteohjelmiston ' +
                    'infrastruktuurin pääsynvalvonta- ja tunkeutumishälytysjärjestelmiin. Älyverkko-ohjaimet ja liitäntäpaneelit ' +
                    'ohjaavat ja valvovat pääsynvalvonta- ja tunkeutumishälytysjärjestelmää.'
        }
    },
    'contact': {
        'info': {
            'h_1': '',
        },
        'body': {
            'h3_1': 'LONIX SOLUTIONS / COBA International Oy',
            'p_1_0': 'Läkkisepäntie 4 A 7\n' +
                    'FI-00620 Helsinki, FINLAND\n' +
                    'Tel. +358 9 349 9853\n' +
                    'info [at] lonix.com',
            'h3_2': 'SALES',
            'p_2_0': 'Tuomas Koskenranta\n' +
                    'tuomas.koskenranta [at] lonix.com\n' +
                    'Tel. 050 352 8333',
            'h3_3': 'DOMESTIC HANDLING',
            'p_3_0': 'Läkkisepäntie 4 A 7\n' +
                    '00620 Helsinki\n' +
                    'FINLAND\n' +
                    'Tel. 050 373 4340',
            'h3_4': 'MAINTENANCE AND TECHNICAL SUPPORT ',
            'p_4_0': 'huolto [at] lonix.com\n' +
                    '\n' +
                    'Jarkko Suomalainen (maintenance and commissioning)\n' +
                    'Tel. 050 373 4340\n' +
                    '\n' +
                    'Jukka Jussila (software, development and licenses)\n' +
                    'Puh. 050 593 6550',
            'h3_5':  'INTERNATIONAL NETWORK',
            'p_5_0': 'In addition to the Nordic countries, Lonix has representatives in CIS-countries, Middle and Far East, ' +
                     'Latin America and Africa.. ',
            'h3_6': 'MIDDLE EAST AND AFRICA',
            'p_6_0': 'COBA Middle East FZCO\n\n' +
                    'Building 5W, Office A221, Dubai Airport Free Zone\nP.O.Box 54393\nDubai, United Arab Emirates\n\nTel +971 4 214 6251\nFax +971 4 214 6252',
        },
    }
};
